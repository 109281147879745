<template>
  <Card title="Levers" opened collapse withDivider>
    <Skeleton v-if="loading" height="496px" />
    <ErrorState v-else-if="error" :error="error" @retry="requestInventory()" />
    <Box v-else flex direction="col" spaceY="4">
      <LeverList @showDrawer="showLeverDrawer" />
      <Box flex justify="between">
        <AddLink @click="addLever()">
          Lever
        </AddLink>
        <Box flex col alignItems="end">
          <Typography variant="body2" color="textSecondary">
            Total allocated: <Typography variant="body2" bold :color="totalAllocatedColor">
              {{ totalAllocated }}%
            </Typography>
          </Typography>
          <FormError :id="getId('levers.totalAllocatedValidation')" pt="2" />
        </Box>
      </Box>
    </Box>

    <LeverDrawer />
  </Card>
</template>

<script setup lang="ts">
import { AddLink, Box, Card, ErrorState, FormError, Skeleton, Typography } from '@lasso/luikit'
import { computed } from 'vue'

import { useCardLevers } from './useCardLevers'
import LeverList from './LeverList.vue'
import {
  LeverDrawer,
  useProvideLeverDrawer,
} from './LeverDrawer'

const { loading, levers, addLever, getId, totalAllocated, requestInventory, error, updateLever } = useCardLevers()!

const { showLeverDrawer } = useProvideLeverDrawer({
  updateLever: (value) => {
    const index = levers.value.findIndex(({ localId }) => value.localId === localId)

    if (index !== -1) {
      updateLever(index, value)
    }
  },
})

const totalAllocatedColor = computed(() => {
  if (totalAllocated.value > 100) {
    return 'error'
  }

  if (totalAllocated.value === 100) {
    return 'success'
  }

  return 'textPrimary'
})
</script>
