<template>
  <Drawer
    title="Edit Lever"
    size="xl"
    :modelValue="isLeverDrawerOpened"
    noSelfClose
    @close="hideLeverDrawer()"
  >
    <LeverForm v-if="isLeverDrawerOpened" :key="editableLever?.localId" />
  </Drawer>
</template>

<script setup lang="ts">
import { Drawer } from '@lasso/luikit'

import { useLeverDrawer } from './useLeverDrawer'

import LeverForm from './LeverForm.vue'

const { hideLeverDrawer, isLeverDrawerOpened, editableLever } = useLeverDrawer()!
</script>
