import { whenever } from '@vueuse/core'
import { createInjectionState } from '@lasso/shared/utils'
import { RefOrGetter } from '@lasso/shared/types'
import { Ref, computed, toValue, watch } from 'vue'
import { UseFormAddonsContext, useApi } from '@lasso/shared/hooks'
import { isEqual } from 'lodash-es'
import { ChannelType, useActivationApi } from '@lasso/api-activation/activation'

import { AdGroupCreative } from '../../../shared/creatives'
import { getIsEndemicChannel } from '../../../shared/channels'

import { getMediaBidDefaultValue } from '../../../shared'

import { targetingBlockKeys } from './consts'
import { buildLever, getLeversTotalBudget } from './utils'
import { AdGroupLever, CardLeversFormInput } from './types'

export const [useProvideCardLevers, useCardLevers] = createInjectionState(({
  isCardEnabled,
  isDirectSold,
  channelId,
  marketerId,
  advertiserId,
  advancedOptimization,
  creativesLoading,
  selectedCreatives,
  isOptimizationTypeSelected,
  creativePreviewBaseUrl,
  useFieldModelsStartingWith,
  useFieldArray,
  getId,
  validateField,
  setInitialValue,
}: {
  isCardEnabled: RefOrGetter<boolean>
  isDirectSold: boolean
  channelId: Ref<ChannelType>
  marketerId: Readonly<Ref<number>>
  advertiserId: Readonly<Ref<number>>
  advancedOptimization: Readonly<Ref<boolean>>
  creativesLoading: Readonly<Ref<boolean>>
  selectedCreatives: Readonly<Ref<AdGroupCreative[]>>
  isOptimizationTypeSelected: Readonly<Ref<boolean>>
  creativePreviewBaseUrl: string
} & UseFormAddonsContext<CardLeversFormInput>) => {
  const { levers } = useFieldModelsStartingWith('levers', ['levers'])
  const leversArray = useFieldArray('levers.levers')

  const api = useActivationApi()

  const updateLever = (index: number, value: Partial<AdGroupLever>) => {
    const lever = leversArray.fields.value[index]

    if (!lever) {
      return
    }

    leversArray.update(index, { ...lever.value, ...value })

    if (targetingBlockKeys.some(key => key in value)) {
      validateField(`levers.levers[${index}].targetingBlock`)
    }

    if ('targetBudget' in value) {
      validateField('levers.totalAllocatedValidation')
    }
  }

  const addLever = () => {
    leversArray.push(buildLever(isOptimizationTypeSelected.value ? 0 : null))
  }

  const removeLever = (index: number) => {
    leversArray.remove(index)
  }

  const totalAllocated = computed(() => {
    return getLeversTotalBudget(levers.value)
  })

  whenever(() => toValue(isCardEnabled) && isOptimizationTypeSelected.value && advancedOptimization.value, () => {
    if (levers.value.some(lever => lever.bid !== 0)) {
      levers.value = levers.value.map(lever => ({ ...lever, bid: 0 }))
    }
  }, { immediate: true })

  const {
    loading: inventoryLoading,
    data: inventoryResponse,
    error,
    request: requestInventory,
  } = useApi(
    api.getInventoryLists,
    () => toValue(isCardEnabled) ? [{ marketerId: marketerId.value }] : null,
    { refetch: true },
  )

  const inventory = computed(() => {
    const inventory = inventoryResponse.value?.data ?? []

    return inventory.filter(item => item.advertiserId === advertiserId.value || !item.advertiserId || item.internal)
  })

  const loading = computed(() => creativesLoading.value || inventoryLoading.value)

  const getCreativePreviewUrl = (creative: AdGroupCreative) => {
    return `${creativePreviewBaseUrl}/api/creative/preview/${creative.id}`
  }

  const getDetailedCreatives = (index: number) => {
    return selectedCreatives.value.filter((creative) => {
      return levers.value[index]?.creativeIds.includes(creative.id)
    })
  }

  const getDetailedInventory = (index: number) => {
    return inventory.value.filter((inventory) => {
      return levers.value[index]?.inventoryList.find(({ id }) => id === inventory.id)
    })
  }

  watch(selectedCreatives, (selectedCreatives) => {
    const newLevers = levers.value.map((lever) => {
      return {
        ...lever,
        creativeIds: lever.creativeIds.filter((leverCreativeId) => {
          return selectedCreatives.some(creative => creative.id === leverCreativeId)
        }),
      }
    })

    if (!isEqual(newLevers, levers.value)) {
      levers.value = newLevers
    }
  })

  watch(channelId, () => {
    leversArray.fields.value.forEach((_, index) => {
      setInitialValue(
        `levers.levers[${index}].bid`,
        getMediaBidDefaultValue(channelId.value),
      )
    })
  })

  const isEndemicChannel = computed(() => getIsEndemicChannel(channelId.value))

  return {
    channelId,
    isDirectSold,
    isEndemicChannel,

    loading,
    error,
    requestInventory,
    inventory,

    isOptimizationTypeSelected,
    selectedCreatives,
    totalAllocated,

    getId,

    levers,
    updateLever,
    addLever,
    removeLever,

    getCreativePreviewUrl,
    getDetailedCreatives,
    getDetailedInventory,
  }
})
