import { useApiManual } from '@lasso/shared/hooks'

import { AdGroupSplitData, EntityStatus, useActivationApi } from '@lasso/api-activation/activation'

import { AdGroupLever } from './types'
import { leverToApiSplit } from './utils'

export const useCardLeversSubmit = () => {
  const { requestThrows: updateExampleInternal } = useApiManual(useActivationApi().linkLevers)

  const updateLevers = async ({ levers, initialLevers, adGroupId }: {
    levers: AdGroupLever[]
    initialLevers: AdGroupLever[]
    adGroupId: number
  }) => {
    const activeSplits = levers.map((lever, index) => leverToApiSplit(lever, index + 1))
    const removedSplits = initialLevers
      .map((lever, index) => leverToApiSplit(lever, index + 1))
      .filter(lever => lever.id && !activeSplits.find(split => split.id === lever.id))
      .map((lever) => {
        return {
          ...lever,
          statusId: EntityStatus.REMOVED,
        } as unknown as AdGroupSplitData
      })

    await updateExampleInternal({
      adGroupID: adGroupId,
      adGroupSplitsArray: [
        ...activeSplits,
        ...removedSplits,
      ],
    })
  }

  return { updateLevers }
}
