import { computed, ref } from 'vue'
import { createInjectionState } from '@lasso/shared/utils'

import { AdGroupLever } from '../types'

import { LeverEditPanelScrollTarget } from './types'

export const [useProvideLeverDrawer, useLeverDrawer] = createInjectionState(({
  updateLever,
}: {
  updateLever: (value: AdGroupLever) => void
}) => {
  const editableLever = ref<AdGroupLever | null>(null)
  const isLeverDrawerOpened = ref(false)
  const drawerScrollTarget = ref<LeverEditPanelScrollTarget | null>(null)

  const showLeverDrawer = (lever: AdGroupLever, scrollTarget: LeverEditPanelScrollTarget | null = null) => {
    editableLever.value = lever
    drawerScrollTarget.value = scrollTarget
    isLeverDrawerOpened.value = true
  }

  const hideLeverDrawer = () => {
    isLeverDrawerOpened.value = false
    editableLever.value = null
    drawerScrollTarget.value = null
  }

  const saveLever = (lever: AdGroupLever) => {
    if (editableLever.value) {
      updateLever(lever)
    }

    hideLeverDrawer()
  }

  return {
    isLeverDrawerOpened: computed(() => isLeverDrawerOpened.value && Boolean(editableLever.value)),
    editableLever,
    drawerScrollTarget,

    showLeverDrawer,
    hideLeverDrawer,
    saveLever,
  }
})
